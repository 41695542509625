import * as React from 'react'
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Layout from '../components/layout'
import Seo from '../components/seo'
import NewsDetailFirst from '../components/FirstNewsDetail'
import NewsContentDetail from '../components/NewsContentDetail'
import RelatedNews from '../components/RelatedNews'
import Twitter from '../components/Twitter'
import CommunityActivityFirstComponent from '../components/CommunityActivityFirstComponent'
import WhoWeAre from '../components/WhoWeAre'
import ComboWrapperCICActivity from '../components/ComboWrapperCICActivity'

export default function CICActivityDetail({pageContext, location}) {
  
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let mainUrl= location.state?.parenturl
  let seo = pageContext?.node?.node?.seo
  let translatedURI = pageContext?.node?.node?.translations[0]?.uri
  if(!mainUrl){
     mainUrl = "/"+locale
  }

    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
        <div className="cic-activity-details-who-we-are">
        <WhoWeAre
          title={""}
          name={pageContext?.node?.node?.title}
          description={""}
        />
        </div>
        <CommunityActivityFirstComponent data={pageContext?.node?.node} />
        <ComboWrapperCICActivity data={pageContext?.node?.node?.cicActivity} locale={locale}/>
       
        </Layout>
        </>
    )
}
